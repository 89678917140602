import * as B from 'quickstart/blocks/block'
import * as R from 'rambdax'
import {ReactNode} from 'react'
import {Root, createRoot} from 'react-dom/client'
import {camelCase, kebabCase, logger, meta, nullish, parseBool} from 'tizra'

const log = logger('elements')

const bool = (x: unknown) => parseBool(x) ?? (nullish(x) ? false : true)

// underscored to shush eslint
const _num = (x: unknown) => parseInt(x as string) ?? null

const str = (x: unknown) => (nullish(x) ? null : `${x}`)

type Converter = typeof bool | typeof _num | typeof str

type PropsAttributes = Record<string, Converter>

type Props<T extends PropsAttributes> = {
  [k in keyof T]?: Exclude<ReturnType<T[k]>, null>
}

const toObservedAttributes = (pa: PropsAttributes, extra: string[] = []) =>
  [...new Set([...Object.keys(pa).map(kebabCase), ...extra])].sort()

export const installCustomElements = (
  wrapperProps: Omit<B.ComponentWrapperProps, 'children'>,
) => {
  abstract class TizraCustomElementBase extends HTMLElement {
    static propsAttributes = {tizraId: str, urlId: str}
    static observedAttributes = toObservedAttributes(this.propsAttributes)
    props: Props<typeof TizraCustomElementBase.propsAttributes>
    tizraId: string

    private root: Root | null
    abstract Component: () => ReactNode

    constructor() {
      super()
      this.root = null
      this.props = {}
      this.tizraId = ''
    }

    connectedCallback() {
      const wrapper = document.createElement('div')
      this.appendChild(wrapper)
      this.root = createRoot(wrapper)
      this.render()
    }

    disconnectedCallback() {
      this.root?.unmount()
      this.root = null
    }

    render() {
      const {Component} = this
      this.root?.render(
        <B.ComponentWrapper {...wrapperProps}>
          <Component />
        </B.ComponentWrapper>,
      )
    }

    attributeChangedCallback(name: string, _old: unknown, _value: unknown) {
      const pa = (this.constructor as typeof TizraCustomElementBase)
        .propsAttributes
      const pk = camelCase(name) as keyof typeof pa
      const pv = pa[pk]?.(_value)
      log.debug?.('attributeChangedCallback', {name, _old, _value, pk, pv})
      if (pv === undefined) {
        log.warn(
          `${this.constructor.name}.attributeChangedCallback ignored unknown`,
          {name, _value},
        )
        return
      }
      if (pv === null) {
        const {[pk]: _, ...newProps} = this.props
        this.props = newProps
      } else {
        this.props = {...this.props, [pk]: pv}
      }
      if (pk === 'tizraId' || pk === 'urlId') {
        this.tizraId = pv ?? ''
      }
      this.render()
    }
  }

  class TizraCoverElement extends TizraCustomElementBase {
    static propsAttributes = {
      ...super.propsAttributes,
      width: str,
      noLink: bool,
    }
    static observedAttributes = toObservedAttributes(
      this.propsAttributes,
      super.observedAttributes,
    )
    declare props: Props<typeof TizraCoverElement.propsAttributes>

    Component = () => {
      const context = B.useBlockContext()
      const tizraId = this.tizraId || context.tizraId
      const linked = tizraId !== context.tizraId && !this.props.noLink
      const metaObj = B.useMetaObj({tizraId})
      if (!metaObj) return null
      let thumb = (
        <B.MetaThumb
          fallback
          metaObj={metaObj}
          position="top left"
          shadowed
          {...R.pick(['width'], this.props)}
        />
      )
      if (linked) {
        thumb = <B.MetaLink metaObj={metaObj}>{thumb}</B.MetaLink>
      }
      return thumb
    }
  }

  class TizraImageElement extends TizraCustomElementBase {
    static propsAttributes = {
      ...super.propsAttributes,
      alt: str,
      width: str,
      href: str,
      src: str,
    }
    static observedAttributes = toObservedAttributes(
      this.propsAttributes,
      super.observedAttributes,
    )
    declare props: Props<typeof TizraImageElement.propsAttributes>

    Component = () => {
      const {tizraId} = this
      const metaObj = B.useMetaObj(tizraId ? {tizraId} : false)
      const {href, src = tizraId && `/${tizraId}/~stream`} = this.props
      if (!src) return null
      let img = (
        <B.Image
          alt={meta.string(metaObj, 'Description') || meta.name(metaObj)}
          src={src}
          {...R.pick(['alt', 'width'], this.props)}
          contain
        />
      )
      if (href) {
        img = <B.UniversalLink href={href}>{img}</B.UniversalLink>
      }
      return img
    }
  }

  class TizraPlayerElement extends TizraCustomElementBase {
    Component = () => {
      const context = B.useBlockContext()
      const tizraId = this.tizraId || context.tizraId
      const [visible, visRef] = B.useVisible({sticky: true})
      const metaObj = B.useMetaObj({enabled: visible, tizraId})
      const [oEmbed] = B.useOEmbed({
        metaObj,
        // We're already checking visibility to load metaObj, so we can tell
        // useOEmbed to load eagerly.
        loading: 'eager',
      })

      // Returning false in react means don't call me again.
      // TODO: Show some kind of problem indicator.
      if (!oEmbed.isPending && !oEmbed.isHtml) return false

      return (
        <div ref={visRef}>
          {oEmbed.isHtml && <B.VideoPlayer oEmbed={oEmbed} />}
        </div>
      )
    }
  }

  class TizraVideoElement extends TizraPlayerElement {}

  customElements.define('t-cover', TizraCoverElement)
  customElements.define('t-image', TizraImageElement)
  customElements.define('t-player', TizraPlayerElement)
  customElements.define('t-video', TizraVideoElement)
}
