import {CheckoutField} from 'tizra'
import {F} from '../../block'
import {ComponentProps, ReactNode, useMemo} from 'react'
import {JsonValue} from 'type-fest'

export interface CheckoutFormFieldProps {
  field: CheckoutField
  label?: string
  autoComplete?: string
  options?: Array<{
    value: JsonValue
    label?: ReactNode
    disabled?: boolean
  }>
  type?: ComponentProps<'input'>['type']
}

export const CheckoutFormField = ({
  field,
  label: _label,
  options: _options,
  type,
  ...rest
}: CheckoutFormFieldProps) => {
  // Don't assume the server has supplied labels on options.
  _options = _options || field.options // don't need both in useMemo dep
  const options = useMemo(
    () => _options?.map(o => ({...o, label: o.label ?? `${o.value}`})),
    [_options],
  )

  const props = {
    name: field.name,
    label: _label ?? field.prompt,
    required: !field.optional,
    ...rest,
  }

  if (field.type === 'boolean') {
    return <F.Checkbox {...props} />
  }

  if (options) {
    return <F.Select {...props} options={options} />
  }

  if (field.type === 'integer') {
    return <F.Input mode="integer-null" type={type} {...props} />
  }

  return <F.Input {...props} type={type} />
}
