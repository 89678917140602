import {dataProps} from 'quickstart/theme'
import {
  ComponentProps,
  createContext,
  Fragment,
  ReactNode,
  useContext,
} from 'react'
import {Error} from './Error'
import {Label} from './Label'
import * as S from './styles'

// copy-pasta from ariakit
interface StringLike {
  toString: () => string
  valueOf: () => string
}

export const defaultSize = 'lg'

export type FieldSize = 'sm' | 'md' | 'lg'

// This exists to push field size information into the SelectPopover portal,
// since we can't get there with CSS.
export const FieldContext = createContext<{size: FieldSize}>({
  size: defaultSize,
})

export interface FieldPropsBase {
  name: StringLike
  label?: ReactNode
  rightLabel?: ReactNode
  hint?: ReactNode
  size?: FieldSize
  type?: 'checkbox' | 'checklist' | 'input' | 'radio' | 'select'
}

export interface FieldProps
  extends FieldPropsBase,
    ComponentProps<typeof S.Field> {}

export const Field = ({
  name,
  label,
  rightLabel,
  hint,
  size,
  type = 'input',
  children,
  ...props
}: FieldProps) => {
  // Get the wrapping FieldContext provided by Form
  const fieldContext = useContext(FieldContext)
  size ||= fieldContext.size

  const indentLabel = type === 'input' || type === 'select'
  const inputLast = type === 'checklist' || type === 'radio'

  const parts = {
    label: (label || rightLabel) && (
      <S.LabelWrapper {...dataProps({indentLabel})} key="label">
        <Label name={name}>{label}</Label>
        <S.RightLabel>{rightLabel}</S.RightLabel>
      </S.LabelWrapper>
    ),
    input: <Fragment key="input">{children}</Fragment>,
    error: <Error name={name} key="error" />,
    hint: <S.Hint key="hint">{hint}</S.Hint>,
  }

  const order: Array<keyof typeof parts> =
    inputLast ?
      ['label', 'hint', 'error', 'input']
    : ['label', 'input', 'error', 'hint']

  return (
    <FieldContext value={{size}}>
      <S.Field {...dataProps({size})} {...props}>
        {order.map(o => parts[o])}
      </S.Field>
    </FieldContext>
  )
}
