import * as AK from '@ariakit/react'
import * as R from 'rambdax'
import {ComponentProps, useState} from 'react'
import {debugging, IS_CHROMATIC, IS_STORYBOOK, logger} from 'tizra'
import * as B from '../../block'
import {F} from '../../block'
import {StepComponent} from '../types'
import {CheckoutFormField} from './CheckoutFormField'

const log = logger('CartBlock/CheckoutStep')

interface FormSummaryProps
  extends Omit<ComponentProps<typeof B.Text>, 'children'> {
  store: AK.FormStore
}

const FormSummary = ({store, ...props}: FormSummaryProps) => {
  const values = AK.useStoreState(store, 'values')
  return (
    <B.Text {...props}>
      {Object.entries(values)
        .filter(([, v]) => v !== undefined)
        .map(([k, v], i) => (
          <span key={i}>
            {k}: {v}
            <br />
          </span>
        ))}
    </B.Text>
  )
}

export const GenericStep: StepComponent = ({active, step, submit}) => {
  const infoRequired = step.infoRequired || []
  const buttonPrompt = step.buttonPrompt || 'Continue'

  const [errorMessage, setErrorMessage] = useState<string>()

  const defaultValues = Object.fromEntries(
    infoRequired.map(f => [f.name, f.defaultValue]),
  )

  const form = F.useFormStore({defaultValues})

  form.useSubmit(async ({values}) => {
    const data = await submit(step.name, values)
    if (!data) return
    const {errors, message} = data
    setErrorMessage(message) // don't worry about reason
    if (errors) {
      form.setErrors(errors ? R.map(R.prop('message'), errors) : {})
    }
  })

  return (
    <F.Form
      store={form}
      debug={!!debugging() || (IS_STORYBOOK && !IS_CHROMATIC)}
    >
      <B.FormShell
        variant="fluid"
        errorMessage={errorMessage}
        primaryButton={{type: 'submit', children: buttonPrompt}}
        style={{display: active ? undefined : 'none'}}
      >
        {infoRequired.map(field => (
          <CheckoutFormField key={field.name} field={field} />
        ))}
      </B.FormShell>
      <FormSummary
        store={form}
        style={{display: !active ? undefined : 'none'}}
      />
    </F.Form>
  )
}
