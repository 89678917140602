import * as AK from '@ariakit/react'
import * as R from 'rambdax'
import {useState} from 'react'
import {debugging, IS_CHROMATIC, IS_STORYBOOK, logger} from 'tizra'
import * as B from '../../block'
import {F} from '../../block'
import {StepComponent} from '../types'
import {CheckoutFormField, CheckoutFormFieldProps} from './CheckoutFormField'
import {countries, states} from './cokesbury'

const log = logger('CartBlock/UmphBillingStep')

type SK = keyof typeof states

const UMPH_FIELD_NAMES = [
  'first-name',
  'last-name',
  'email',
  'address1',
  'address2',
  'country',
  'city',
  'state',
  'postal-code',
  'phone',
] as const

type UmphFieldName = (typeof UMPH_FIELD_NAMES)[number]

export const UmphBillingStep: StepComponent = ({active, step, submit}) => {
  const infoRequired = step.infoRequired || []
  const buttonPrompt = step.buttonPrompt || 'Continue'

  const [errorMessage, setErrorMessage] = useState<string>()

  const defaultValues = Object.fromEntries(
    infoRequired.map(f => [f.name, f.defaultValue ?? '']),
  )

  const extraInfoRequired = R.omit(UMPH_FIELD_NAMES as any, infoRequired)

  const form = F.useFormStore({defaultValues})

  form.useSubmit(async ({values}) => {
    const data = await submit(step.name, values)
    if (!data) return
    const {errors, message} = data
    setErrorMessage(message) // don't worry about reason
    if (errors) {
      form.setErrors(errors ? R.map(R.prop('message'), errors) : {})
    }
  })

  const inp = (
    name: UmphFieldName,
    {
      autoComplete,
      ...props
    }: Omit<CheckoutFormFieldProps, 'field'> & {label?: string} = {},
  ) => {
    const field = infoRequired.find(f => f.name === name)
    return (
      field && (
        <CheckoutFormField
          key={field.name}
          field={field}
          autoComplete={autoComplete && `billing ${autoComplete}`}
          {...props}
        />
      )
    )
  }

  const values = AK.useStoreState(form, 'values')
  const country = values.country || 'USA'

  return (
    <>
      <B.AddressSummary
        values={{
          ...values,
          firstName: values['first-name'],
          lastName: values['last-name'],
          zip: values['postal-code'],
          country: values['country'],
        }}
        style={{display: !active ? undefined : 'none'}}
      />
      <F.Form
        store={form}
        debug={!!debugging() || (IS_STORYBOOK && !IS_CHROMATIC)}
      >
        <B.FormShell
          variant="fluid"
          primaryButton={{type: 'submit', children: buttonPrompt}}
          errorMessage={errorMessage}
          style={{display: active ? undefined : 'none'}}
        >
          <B.Flex>
            {inp('first-name', {autoComplete: 'given-name'})}
            {inp('last-name', {autoComplete: 'family-name'})}
          </B.Flex>
          {inp('country', {
            autoComplete: 'country',
            options: countries,
          })}
          {inp('address1', {
            autoComplete: 'address-line1',
            label: 'Street address',
          })}
          {inp('address2', {
            autoComplete: 'address-line2',
            label: 'Apartment, suite, etc. (optional)',
          })}
          <B.Flex>
            {inp('city')}
            {country in states ?
              inp('state', {
                label: country === 'CAN' ? 'Province' : 'State',
                options: states[country as SK],
              })
            : inp('state')}
            {inp('postal-code', {
              label: country === 'USA' ? 'Zip code' : 'Postal code',
            })}
          </B.Flex>
          {inp('email', {type: 'email', autoComplete: 'email'})}
          {inp('phone', {type: 'tel', autoComplete: 'tel'})}
          {Object.keys(extraInfoRequired).map(name =>
            inp(name as UmphFieldName),
          )}
        </B.FormShell>
      </F.Form>
    </>
  )
}
