import * as AK from '@ariakit/react'
import {dataProps} from 'quickstart/theme'
import {ComponentProps} from 'react'
import {debugging} from 'tizra'
import {SetRequired} from 'type-fest'
import {FieldContext, FieldSize, defaultSize} from './Field'
import * as S from './styles'

export {useFormContext, useFormStore} from '@ariakit/react'

interface DebugProps extends Omit<ComponentProps<'pre'>, 'children'> {
  store?: AK.FormStore
}

export const Debug = (props: DebugProps) => {
  const context = AK.useFormContext()
  const store = props.store || context
  const values = AK.useStoreState(store, state => state?.values)
  return (
    <pre
      {...props}
      style={{
        border: '1px dashed black',
        padding: '5px',
        overflowX: 'scroll',
        fontSize: '14px',
        ...props.style,
      }}
    >
      {JSON.stringify(values, null, 2)}
    </pre>
  )
}

export interface FormProps
  extends SetRequired<ComponentProps<typeof AK.Form>, 'store'> {
  debug?: boolean
  size?: FieldSize
}

export const Form = ({
  debug = !!debugging(),
  size = defaultSize,
  store,
  children,
  ...props
}: FormProps) => {
  // Don't eagerly validate until first attempted submit fails.
  // https://github.com/ariakit/ariakit/discussions/4208
  const submitted = AK.useStoreState(store, state => !!state.submitFailed)

  return (
    <>
      <FieldContext value={{size}}>
        <AK.Form
          render={<S.Form />}
          {...dataProps({size})}
          validateOnBlur={submitted}
          validateOnChange={submitted}
          resetOnSubmit={false}
          store={store}
          {...props}
        >
          {children}
          {debug && <Debug store={store} />}
        </AK.Form>
      </FieldContext>
    </>
  )
}
