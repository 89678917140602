import {logger} from 'tizra'
import {GenericStep} from './steps/GenericStep'
import {UmphBillingStep} from './steps/UmphBillingStep'
import {StepComponent, StepComponentProps} from './types'

const log = logger('CartBlock/CheckoutStep')

export const decideStepComponent = (
  props: StepComponentProps,
): StepComponent => {
  const {checkout, step} = props
  if (checkout?.name === 'umph' && step?.name === 'billing-address') {
    return UmphBillingStep
  }
  return GenericStep
}

export const CheckoutStep: StepComponent = props => {
  const Component = decideStepComponent(props)
  return <Component {...props} />
}
