import * as AK from '@ariakit/react'
import {ReactNode} from 'react'
import {BaseCheckbox} from './BaseCheckbox'
import * as S from './styles'
import {Field, FieldPropsBase} from './Field'

interface FormChecklistProps<V extends string = string>
  extends Omit<AK.FormCheckboxProps, 'name' | 'render' | 'required' | 'value'> {
  name: AK.FormCheckboxProps['name']
  options: Array<{value: V; label: ReactNode; disabled?: boolean}>
}

const FormChecklist = <V extends string = string>({
  name,
  options,
  ...props
}: FormChecklistProps<V>) => (
  <S.Checklist>
    {options.map(o => (
      <AK.FormCheckbox
        key={o.value}
        name={name}
        value={o.value}
        render={<BaseCheckbox label={o.label} />}
        clickOnEnter
        disabled={o.disabled}
        {...props}
      />
    ))}
  </S.Checklist>
)

interface ChecklistFieldProps
  extends FieldPropsBase,
    Omit<FormChecklistProps, 'size' | 'type'> {}

const ChecklistField = ({
  name,
  label,
  rightLabel,
  hint,
  size,
  type = 'checklist',
  ...props
}: ChecklistFieldProps) => (
  <Field
    name={name}
    label={label}
    rightLabel={rightLabel}
    hint={hint}
    size={size}
    type={type}
  >
    <FormChecklist name={name} {...props} />
  </Field>
)

export type {ChecklistFieldProps as ChecklistProps}

export const Checklist = Object.assign(ChecklistField, {Input: FormChecklist})
