import * as AK from '@ariakit/react'
import {dataProps} from 'quickstart/theme'
import {ReactNode} from 'react'
import {Field, FieldPropsBase} from './Field'
import * as S from './styles'

interface FormRadioProps<V extends string = string> {
  name: AK.FormRadioProps['name']
  options: Array<{value: V; label: ReactNode; disabled?: boolean}>
  disabled?: boolean
  required?: boolean
}

const FormRadio = <V extends string = string>({
  name,
  options,
  required,
  disabled: allDisabled,
}: FormRadioProps<V>) => {
  const form = AK.useFormContext()
  if (!form) throw new Error('Radio must be used within a Form')

  form.useValidate(() => {
    console.log({required, value: form.getValue(name)})
    if (required && !form.getValue(name)) {
      form.setError(name, 'Please select an option.')
    }
  })

  const value = form.useValue(name)

  return (
    <AK.FormRadioGroup render={<S.Radio data-disabled={!!allDisabled} />}>
      {options.map(o => {
        const checked = value === o.value
        const disabled = allDisabled || o.disabled
        return (
          <S.RadioWrapper {...dataProps({disabled})} key={o.value}>
            <AK.FormRadio
              name={name}
              value={o.value}
              render={props => (
                // S.Wrapper is display:grid, and S.Radio attempts
                // vertical-align:baseline. This seems to require a div wrapper
                // to act as the grid item.
                <S.RadioColumn>
                  <S.RadioButton {...props} disabled={disabled} />
                </S.RadioColumn>
              )}
              disabled={disabled}
            />
            <S.RadioLabel {...dataProps({checked})}>{o.label}</S.RadioLabel>
          </S.RadioWrapper>
        )
      })}
    </AK.FormRadioGroup>
  )
}

interface RadioFieldProps extends FieldPropsBase, FormRadioProps {}

const RadioField = ({
  name,
  label,
  rightLabel,
  hint,
  size,
  type = 'radio',
  ...props
}: RadioFieldProps) => (
  <Field
    name={name}
    label={label}
    rightLabel={rightLabel}
    hint={hint}
    size={size}
    type={type}
  >
    <FormRadio name={name} {...props} />
  </Field>
)

export type {RadioFieldProps as RadioProps}

export const Radio = Object.assign(RadioField, {Input: RadioField})
