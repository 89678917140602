import {ReactNode, useMemo, useState} from 'react'
import {CheckoutStep as TCheckoutStep, logger, truthy} from 'tizra'
import {SetRequired} from 'type-fest'
import * as B from '../block'
import {CheckoutStep} from './CheckoutStep'
import {FulfillerButtons} from './FulfillerButtons'
import {PickCheckout} from './PickCheckout'
import {SignIn} from './SignIn'
import {Config} from './admin'
import * as S from './styles'
import {StepComponent} from './types'

const log = logger('CartBlock/CheckoutWizard')

const CIRCLED_NUMBERS = [
  '⓪',
  '①',
  '②',
  '③',
  '④',
  '⑤',
  '⑥',
  '⑦',
  '⑧',
  '⑨',
] as const

const StepHeading = ({
  number,
  children,
}: {
  children: ReactNode
  number: number
}) => {
  const checkoutApiEnabled = B.useHack('checkoutApi')
  const checkoutNumbersEnabled = B.useHack('checkoutNumbers')
  return checkoutNumbersEnabled || !checkoutApiEnabled ?
      <S.NumberedHeading>
        <div>{CIRCLED_NUMBERS[number]}</div>
        <div>{children}</div>
      </S.NumberedHeading>
    : <S.Heading>{children}</S.Heading>
}

interface CheckoutWizardProps
  extends SetRequired<B.UseCheckoutReturn, 'checkouts'> {
  config: Config
  userData: Exclude<ReturnType<typeof B.useUserData>, null>
}

export const CheckoutWizard = ({
  config,
  userData,
  checkouts,
  checkoutName,
  checkout,
  firstIncomplete,
  ...props
}: CheckoutWizardProps) => {
  const showPicker = checkouts.length !== 1

  const [_index, _setIndex] = useState<number>()
  const nSteps = checkout?.steps.length ?? 0
  const lastStep = nSteps ? nSteps - 1 : nSteps
  const firstIncompleteIndex =
    (showPicker ? 2 : 1) + (firstIncomplete ?? lastStep)
  const currentIndex =
    _index ??
    (!userData ? 0
    : !checkoutName ? 1
    : firstIncompleteIndex)
  const setIndex: typeof _setIndex = (...args) => {
    if (args[0] === -1) {
      _setIndex(firstIncompleteIndex)
    } else {
      _setIndex(...args)
    }
  }

  const steps = useMemo<
    Array<{title: string; Component: StepComponent; step: TCheckoutStep}>
  >(() => {
    const checkoutSteps =
      checkout?.steps
        .filter(step => !step.hidden)
        .map(step => ({
          title: step.displayName ?? step.name,
          Component:
            step.name === B.FULFILLER_BUTTONS_STEP_NAME ?
              FulfillerButtons
            : CheckoutStep,
          step,
        })) || []
    return [
      {title: 'Sign in', Component: SignIn, step: {name: '_signin'}},
      showPicker && {
        title: 'Checkout method',
        Component: PickCheckout,
        step: {name: '_pick'},
      },
      ...checkoutSteps,
    ].filter(truthy)
  }, [checkout, showPicker])

  return (
    <B.Stack divided endCapped spacing="lg">
      {steps.map(({title, Component, step}, myIndex) => {
        const active = currentIndex === myIndex
        const canEdit = currentIndex > myIndex
        return (
          <B.Stack key={myIndex}>
            <B.LeftRight>
              <StepHeading number={myIndex + 1}>{title}</StepHeading>
              <B.Text variant="textMd">
                {canEdit ?
                  <B.Link onClick={() => setIndex(myIndex)}>Edit</B.Link>
                : <span style={{visibility: 'hidden'}}>Edit</span>}
              </B.Text>
            </B.LeftRight>
            <Component
              active={active}
              userData={userData}
              config={config}
              setIndex={setIndex}
              myIndex={myIndex}
              currentIndex={currentIndex}
              checkouts={checkouts}
              checkoutName={checkoutName}
              checkout={checkout}
              step={step}
              {...props}
            />
          </B.Stack>
        )
      })}
    </B.Stack>
  )
}
