import * as AK from '@ariakit/react'
import {ComponentProps, ReactNode, useState} from 'react'
import * as R from 'rambdax'
import * as S from './styles'

export interface BaseCheckboxProps
  extends ComponentProps<'input'>,
    AK.FocusableProps<'input'> {
  label?: ReactNode
}

export const BaseCheckbox = ({label, ...props}: BaseCheckboxProps) => {
  // Controlled will have props.checked, uncontrolled will not, but it might
  // have props.defaultChecked.
  const [checked, setChecked] = useState(
    props.checked ?? props.defaultChecked ?? false,
  )
  const dataProps = {
    ...R.filter((_v, k: string) => k.startsWith('data-'), props),
    'data-checked': props.checked ?? checked,
    'data-disabled': !!props.disabled,
  }
  return (
    <S.CheckLabel {...dataProps}>
      <AK.VisuallyHidden>
        <AK.Checkbox
          {...props}
          onChange={event => {
            setChecked(event.target.checked)
            props.onChange?.(event)
          }}
        />
      </AK.VisuallyHidden>
      <S.Check {...dataProps} />
      <span>{label}</span>
    </S.CheckLabel>
  )
}
